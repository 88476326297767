<template>
  <v-main class="mt-16">
    <v-row v-if="tripData">
      <v-col cols="4" color="black">
        <v-container style="max-height: 100vh">
          <v-tabs color="green">
            <v-tab
              v-for="(item, i) in labels"
              :key="i"
              style="width: 33.33333%"
              @click="_clickTab(item)"
            >
              <v-icon>{{ item.icon }}</v-icon>
              <span :class="selectedTab == item ? 'primary--text' : ''">{{
                item
              }}</span>
            </v-tab>
          </v-tabs>
          <!-- <v-row justify="end">
            <v-btn class="sm" @click="_addPlacesOnMap"> show </v-btn>
          </v-row> -->
        </v-container>
        <v-container style="background: #f2f2f2" class="px-0">
          <div
            class="d-flex flex-row justify-space-between px-2"
            align="center"
          >
            <div>
              <v-icon color="black" class="mr-2">mdi-heart-outline</v-icon>
              <span class="font-weight-bold" style="font-size: 14pt"
                >Аяллаа төлөвлөx {{ tripData.ref.path }}
              </span>
            </div>
            <div>
              <v-menu rounded="b-xl" offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon class="white--text" v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, dindex) in actions"
                    :key="dindex"
                    link
                  >
                    <v-list-item-title
                      v-text="item.name"
                      @click="_selectAction(item)"
                    ></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <v-container>
            <v-row justify="space-between" class="px-2">
              <p class="orange--text">+ Add Description</p>
              <v-icon @click="_viewImages"> mdi-eye </v-icon>
            </v-row>
          </v-container>
          <v-container
            style="overflow-y: scroll; height: 800px !important"
            class="py-4 px-0"
          >
            <v-container style="background: #fff" class="px-6 py-10">
              <v-row justify="space-between">
                <p class="font-weight-bold">{{ tripData.name }}</p>
                <div>
                  <v-btn outlined small @click="_removeDay"
                    ><span style="font-size: 22pt">-</span></v-btn
                  >
                  <span style="font-size: 14pt" class="mx-2" v-if="planned">{{
                    planned.length
                  }}</span>
                  <v-btn outlined small @click="_addDay"
                    ><span style="font-size: 22pt">+</span></v-btn
                  >
                </div>
              </v-row>
            </v-container>

            <TripDay
              :tripDay="plannedDay"
              v-for="(plannedDay, tindex) in planned"
              :key="tindex"
              @click.native="_selectDay(plannedDay)"
              datax="bbb"
              v-model="plannedDay.selected"
            ></TripDay>
          </v-container>
        </v-container>
      </v-col>
      <v-col cols="8">
        <v-container class="mt-10" style="height: 100vh">
          <v-tabs v-model="findPlacesTab" centered icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-0">
              Аялаx газрууд
              <v-icon>mdi-view-comfy</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
              Xадгалсан газрууд
              <v-icon>mdi-view-comfy</v-icon>
            </v-tab>
            <v-tab href="#tab-1">
              Шинээр xайx
              <v-icon>mdi-magnify</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="findPlacesTab">
            <v-tab-item value="tab-0">
              <DaysMaps :planned="planned" v-bind="$attrs"></DaysMaps>
            </v-tab-item>
            <v-tab-item value="tab-1">
              <SearchPlaces :tripdata="tripData" v-bind="$attrs"></SearchPlaces>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <TourCollection
                :tripdata="trip"
                :tripRef="tripData.ref.path"
                :planned="planned"
                v-bind="$attrs"
              ></TourCollection>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import TripDay from "./TripDay.vue";
import draggable from "vuedraggable";
import SearchPlaces from "./SearchPlaces";
import TourCollection from "./TourCollection.vue";
import DaysMaps from "./DaysMaps.vue";
const fb = require("../../firebaseConfig.js");
import { get } from "vuex-pathify";
export default {
  components: {
    TripDay,
    draggable,
    SearchPlaces,
    TourCollection,
    DaysMaps,
  },
  computed: {
    cities: get("cities/cities"),
    // totalSales () {
    //   return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
    // },
    plannedDays() {
      var counter = 0;
      for (var pDay of this.planned) {
        counter++;
        pDay.index = counter;
      }
    },
  },
  props: {
    tripId: String,
  },
  data() {
    return {
      selectedTab: null,
      planned: null,
      tripData: null,
      findPlacesTab: null,
      actions: [
        { name: "Нээлттэй болгоx", actionType: "make-public" },
        { name: "Устгаx", actionType: "delete-trip" },

        //         Edit Trip
        // Organize your Trip
        // Add Links to Trip

        // Add Notes to Trip
        // Copy your Trip
      ],
      trip: [
        {
          name: "A",
          dayIndex: 1,
          locations: [
            {
              name: "A",
              id: "dfsdf0",
              type: "location",
              title: "2",
              subtitle: "s2",
              headline: "h2",
            },
          ],
        },
        {
          name: "B",
          dayIndex: 2,
          locations: [
            {
              name: "B",
              id: "dfsdf",
              type: "location",
              title: "2",
              subtitle: "s2",
              headline: "h2",
            },
            {
              name: "bb",
              id: "dfsdf2",
              type: "location",
              title: "4",
              subtitle: "s4",
              headline: "h4",
            },
          ],
        },
      ],
      items: Array.from(
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        function mapFn(element) {
          return { title: element, subtitle: element, headline: element };
        }
      ),
      labels: ["Trip plan"],
    };
  },
  watch: {
    trip: function (val) {
      console.log(val);
    },
  },

  created() {
    if (this.tripId != undefined && this.tripId != null)
      fb.db
        .doc(this.tripId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            let trip = doc.data();
            trip.id = doc.id;
            trip.ref = doc.ref;
            this.tripData = trip;

            var counter = 0;
            if (this.tripData != undefined && this.tripData != null) {
              console.log(this.tripData);
              fb.db
                .collection(this.tripData.ref.path + "/planned")
                .orderBy("createdAt", "desc")
                .onSnapshot((docs) => {
                  this.planned = [];
                  docs.forEach((doc) => {
                    counter++;
                    let tripDay = doc.data();
                    console.log(doc.data());
                    tripDay.id = doc.id;
                    tripDay.ref = doc.ref;
                    tripDay.index = counter;
                    tripDay.showImages = false;
                    this.planned.push(tripDay);
                  });
                });
            }
          }
        })
  },
  methods: {
    _viewImages() {
      console.log("aaa");
      for (var pDay of this.planned) {
        pDay.showImages = !pDay.showImages;
      }
    },
    async citiess() {
      var counter = 0;
      for (const city of this.cities) {
        counter++;
        console.log(city.name);
        const cityCreated = await fb.db
          .collection("countries/Mongolia/cities")
          .add({ name: city.name, index: counter });
        for (const childx of city.sumuud) {
          cityCreated.collection("children").doc().set({ name: childx });
        }
      }
    },
    _selectAction(item) {
      console.log(item);
      if (item.actionType == "delete-trip") {
        var yes = confirm("Delete?");
        if (yes) {
          this.tripData.ref.delete().then(() => {
            this.$router.go(-1);
          });
        }
      }
    },
    _selectDay(t) {
      t.selected = true;
      this.$forceUpdate();
    },
    _addPlace2() {
      console.log("dflsdjfjsdf");
    },
    _removeDay() {
      if (this.planned && this.planned.length > 0) {
        this.planned[0].ref.delete();
      }
    },
    _addDay() {
      console.log(this.tripData.ref.path);
      var newDay = {
        createdAt: new Date(),
        day: 1,
        locations: [],
      };
      fb.db
        .collection(this.tripData.ref.path + "/planned")
        .doc()
        .set(newDay);
      this.trip.push({
        name: this.trip.length,
        dayIndex: this.trip.length + 1,
        locations: [
          {
            name: this.trip.length,
            type: "location",
            title: this.trip.length,
          },
        ],
      });
    },
  },
};
</script>